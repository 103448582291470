function getRoundedValue(value) {
	return value > 1000000
		? `${Math.round(value * 0.000001 * 10) / 10}m`
		: value > 1000
		? `${Math.round(value * 0.001 * 10) / 10}k`
		: value;
}

function matchPattern(pattern) {
	let regex = /^/;
	switch (pattern) {
		case "cf_piva":
			regex = /[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|[A-Za-z]{2}[0-9]{11}/;
			break;
		case "iban":
			regex = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;
			break;
		default:
			regex = "";
	}
	return regex;
}

export { getRoundedValue, matchPattern };
