import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import FileSaver from "file-saver";
import { axiosInstance } from "services/api";

const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: theme.spacing(3),
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
		display: "flex",
		alignItems: "flex-end",
	},
	attachmentsIcon: {
		marginRight: theme.spacing(2),
		fontSize: "2rem",
	},
	attachmentIcon: {
		marginRight: "4px",
	},
}));

export function Email({ email, id }) {
	console.log(email);

	const classes = useStyles();

	const [buffer, setBuffer] = useState();
	const [fileName, setFileName] = useState();

	function getAttachmentById(doc_id, att_id) {
		console.log(att_id);
		axiosInstance
			.get(`/dashboard/docs/${doc_id}/${att_id}`, {
				responseType: "blob",
			})
			.then(({ data }) => {
				setBuffer(data);
			});
	}

	function attachmentDownload() {
		const data = new Blob([buffer]);
		FileSaver.saveAs(data, fileName);
	}

	useEffect(() => {
		if (buffer) {
			attachmentDownload();
		}
	}, [buffer]);

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h5" className={classes.footer}>
					E-mail originale
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle1">
					Data invio: <strong>{email.date.value}</strong>
				</Typography>
				<Typography variant="subtitle1">
					Mittente: <strong>{email.sender.value.toString()}</strong>
				</Typography>
				<Typography variant="subtitle1">
					Destinatario:{" "}
					<strong>{email.receivers.value.toString()}</strong>
				</Typography>
				<Typography variant="subtitle1">
					Oggetto: <strong>{email.subject.value}</strong>
				</Typography>
				{/* <Typography variant="subtitle1">
					Casella postale: <strong>{email.boh.value}</strong>
				</Typography> */}
				<Typography variant="subtitle1" className={classes.footer}>
					<AttachFileIcon className={classes.attachmentsIcon} />

					{email.atts.value.length > 0
						? email.atts.value.map((attachment, index) => (
								<React.Fragment key={index}>
									{attachment.type === "image" ? (
										<ImageIcon
											className={classes.attachmentIcon}
										/>
									) : (
										<PictureAsPdfIcon
											className={classes.attachmentIcon}
										/>
									)}
									<Typography>
										<Link
											href="#"
											onClick={(event) => {
												setFileName(
													attachment.name.replace(
														"^",
														"."
													)
												);
												getAttachmentById(
													id,
													attachment.id
												);
											}}
										>
											{attachment.name.replace("^", ".")}
										</Link>
									</Typography>
								</React.Fragment>
						  ))
						: "Email senza allegati"}
				</Typography>
				<Typography variant="subtitle1" className={classes.footer}>
					<strong>Testo dell&apos;email: </strong>
				</Typography>
				<Typography variant="subtitle1" className={classes.header}>
					{email.body.value.toString()}
				</Typography>
			</Grid>
		</>
	);
}
