import React from "react";
import { Grid } from "@material-ui/core";

export function Settings() {
	return (
		<Grid container justify="center" spacing={2}>
			Settings
		</Grid>
	);
}
