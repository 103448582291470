import React, { Fragment, useState } from "react";
import {
	Drawer,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";
import { drawerWidth } from "config/constants";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1, 0, 3),
		...theme.mixins.toolbar,
		justifyContent: "space-between",
	},
	drawerFooter: {
		marginTop: "auto",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	subNested: {
		paddingLeft: theme.spacing(7),
	},
}));

export function Sidebar({ open, handleDrawerClose }) {
	const classes = useStyles();

	const [selectedItem, setSelectedItem] = useState(null);

	const sidebarElements = {
		main: [
			{
				label: "Dashboard",
				path: "/dashboard",
				icon: <DashboardIcon />,
			},
		],
		footer: [
			{
				label: "Settings",
				path: "/settings",
				icon: <SettingsIcon />,
			},
			{
				label: "Logout",
				action: () => console.log("ciao"),
				icon: <PersonIcon />,
			},
		],
	};

	return (
		<>
			<Drawer
				variant="persistent"
				className={classes.drawer}
				onClose={handleDrawerClose}
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<div className={classes.logo}>
						<img src={logo} alt="Reale Mutua" width="140px" />
					</div>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					{sidebarElements.main.map((element) =>
						element.path ? (
							<Fragment key={element.label}>
								<ListItem
									button
									selected={selectedItem === element.label}
									onClick={() =>
										setSelectedItem(element.label)
									}
									component={Link}
									to={element.path}
								>
									<ListItemIcon>{element.icon}</ListItemIcon>
									<ListItemText primary={element.label} />
								</ListItem>
							</Fragment>
						) : (
							<ListItem
								key={element.label}
								button
								onClick={element.action}
							>
								<ListItemIcon>{element.icon}</ListItemIcon>
								<ListItemText primary={element.label} />
							</ListItem>
						)
					)}
				</List>
				<Divider className={classes.drawerFooter} />
				<List>
					{sidebarElements.footer.map((element) => (
						<Fragment key={element.label}>
							{element.path ? (
								<ListItem
									button
									selected={selectedItem === element.label}
									onClick={() =>
										setSelectedItem(element.label)
									}
									component={Link}
									to={element.path}
								>
									<ListItemIcon>{element.icon}</ListItemIcon>
									<ListItemText primary={element.label} />
								</ListItem>
							) : (
								<ListItem
									key={element.label}
									button
									onClick={element.action}
								>
									<ListItemIcon>{element.icon}</ListItemIcon>
									<ListItemText primary={element.label} />
								</ListItem>
							)}
						</Fragment>
					))}
				</List>
			</Drawer>
		</>
	);
}

Sidebar.propTypes = {
	open: PropTypes.bool,
	handleDrawerClose: PropTypes.func,
};
