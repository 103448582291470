import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import { Route, Switch } from "react-router-dom";
import { theme } from "assets/themes";
import { ConfirmerProvider } from "shared/ConfirmerProvider";
import { history } from "store";
import { Layout } from "views/Layout";
import { Validate } from "views/Validate";

function App() {
	return (
		<ConnectedRouter history={history}>
			<ThemeProvider theme={theme}>
				<ConfirmerProvider>
					<SnackbarProvider>
						<CssBaseline />
						<Switch>
							<Route path="/validate" component={Validate} />
							<Route path="/" component={Layout} />
						</Switch>
					</SnackbarProvider>
				</ConfirmerProvider>
			</ThemeProvider>
		</ConnectedRouter>
	);
}

export default App;
