import React, { forwardRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
	uploadButton: {
		margin: "1rem 0",
		marginRight: "1rem",
		borderRadius: 0,
		minWidth: 240,
	},
}));

// eslint-disable-next-line react/display-name
export const FileUpload = forwardRef(
	({ onUpload, multiple = false, label = "Carica file" }, ref) => {
		const classes = useStyles();

		return (
			<>
				<input
					ref={ref}
					style={{
						display: "none",
					}}
					onChange={(event) =>
						onUpload(
							multiple
								? event.target.files
								: event.target.files[0]
						)
					}
					id="upload-file"
					type="file"
				/>
				<label htmlFor="upload-file">
					<Button
						component="span"
						variant="contained"
						color="secondary"
						size="large"
						className={classes.uploadButton}
					>
						{label}
					</Button>
				</label>
			</>
		);
	}
);

FileUpload.propTypes = {
	handleSetFile: PropTypes.func.isRequired,
	multiple: PropTypes.bool,
	label: PropTypes.string,
};
