import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const danger = "#D16405";
const success = "#64D164";
const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: theme.spacing(3),
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		display: "flex",
		alignItems: "flex-end",
	},
	filename: {
		paddingLeft: "1rem",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "-7rem 0 6rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	dangerAction: {
		backgroundColor: danger,
	},
	successAction: {
		backgroundColor: success,
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
	},
}));

export function Field({ entity, entityRef, error }) {
	const classes = useStyles();
	return (
		<TextField
			fullWidth
			error={!!error}
			inputRef={entityRef}
			// onBlur={() => trigger()}
			// onChange={() => trigger()}
			inputProps={{
				readOnly: entity.readonly,
			}}
			InputLabelProps={{
				shrink: true,
			}}
			label={entity.mandatory ? `${entity.label} *` : entity.label}
			name={entity.name}
			defaultValue={entity.value || ""}
			helperText={
				!!error && (
					<span
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<span>
							{error.type === "required"
								? "campo obbligatorio"
								: error.type === "pattern"
								? "Il valore inserito non è corretto"
								: ""}
						</span>
						<span
							style={{
								float: "right",
							}}
						>
							<WarningIcon
								className={classes.errorAlertIcon}
								fontSize="small"
							/>
						</span>
					</span>
				)
			}
		/>
	);
}
