import React, { useState, useEffect } from "react";
import { Backdrop, Grid, makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Redirect, Route, Switch } from "react-router-dom";
import { Main } from "shared/Main";
import { Sidebar } from "shared/Sidebar";
import { Dashboard } from "views/Dashboard";
import { Settings } from "views/Settings";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer - 1,
	},
}));

export function Layout() {
	const classes = useStyles();

	const wideScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!wideScreen) setOpen(false);
	}, [wideScreen]);

	function handleDrawerOpen() {
		setOpen(true);
	}

	function handleDrawerClose(event) {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setOpen(false);
	}

	return (
		<>
			<Sidebar open={open} handleDrawerClose={handleDrawerClose} />
			{!wideScreen && (
				<Backdrop className={classes.backdrop} open={open} />
			)}
			<Main
				open={open}
				handleDrawerOpen={handleDrawerOpen}
				handleDrawerClose={handleDrawerClose}
			>
				<Grid item xs={12}>
					<Switch>
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/settings">
							<Settings />
						</Route>
						<Route path="*">
							<Redirect to="/dashboard" />
						</Route>
					</Switch>
				</Grid>
			</Main>
		</>
	);
}
