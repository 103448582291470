import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Tooltip,
	Fab,
	InputProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "services/api";
import { Field } from "shared/Field";
import { FileUpload } from "shared/FileUpload";
import { matchPattern } from "utils";

const danger = "#D16405";
const success = "#64D164";

const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: theme.spacing(3),
		// borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		display: "flex",
		alignItems: "flex-end",
	},
	filename: {
		paddingLeft: "1rem",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "-7rem 0 6rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	dangerAction: {
		backgroundColor: danger,
	},
	successAction: {
		backgroundColor: success,
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
	},
}));

export function UploadForm({
	onUpload,
	handleSetFile,
	handleSetTextractor,
	file,
	textractor,
}) {
	const fileInput = useRef(null);
	const { register, handleSubmit, errors, reset } = useForm();

	const classes = useStyles();

	return (
		<Grid item xs={12} className={classes.header}>
			<form id="uploadForm">
				<Box className={classes.uploadButtonWrapper}>
					<Box>
						<FileUpload
							onUpload={handleSetFile}
							label={file?.name}
							className={classes.fileUploaded}
						/>
						{file?.name && (
							<Button
								component="span"
								variant="contained"
								color="secondary"
								size="large"
								className={classes.uploadButton}
								onClick={(event) => onUpload()}
							>
								Carica file
							</Button>
						)}
						{/* <input
							ref={fileInput}
							style={{
								display: "none",
							}}
							onChange={(event) =>
								handleSetFile(event.target.files[0])
							}
							id="upload-file"
							type="file"
						/>
						<label htmlFor="upload-file">
							<Button
								component="span"
								variant="contained"
								color="secondary"
								size="large"
								className={classes.uploadButton}
							>
								Carica file
							</Button>
							<Typography className={classes.filename}>{file && file.name}</Typography>
						</label> */}
					</Box>
					<Box>
						<FormControl component="fieldset">
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={textractor}
											onChange={(event) =>
												handleSetTextractor(!textractor)
											}
											name="enable_textractor"
										/>
									}
									label="Richiedi l'estrazione automatica dei dati"
								/>
							</FormGroup>
						</FormControl>
					</Box>
				</Box>

				{document.sub_label === "REVOCA" && (
					<Box>
						<Box>
							PER RICHIEDERE UNA REVOCA RMA INSERISCI IL NUMERO
							POLIZZA
						</Box>
					</Box>
				)}
			</form>
		</Grid>
	);
}

export function EntitiesForm({ overview, entities = [], id }) {
	const { register, handleSubmit, trigger, errors, reset } = useForm();
	const classes = useStyles();

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	// console.log(history);

	useEffect(() => {
		trigger();
	}, []);

	useEffect(() => {
		reset(entities);
	}, [entities]);

	const actions = [
		{
			icon: <DeleteIcon />,
			label: "Chiude richesta",
			handle: (i) => onDelete(),
			type: "button",
			color: "danger",
		},
		{
			icon: <SaveIcon />,
			label: "Salva richiesta senza passare per l'RPA",
			handle: (i) => onSave(),
			type: "button",
		},
		{
			icon: <CheckIcon />,
			label: "Approva richiesta",
			handle: (i) => console.log(i),
			color: "success",
			type: "submit",
		},
	];

	const onSubmit = (data) => {
		const toSend = {
			label: overview.label.value,
			sub_label: overview.sub_label.value,
			sender: overview.sender.value,
			agenzia: overview.agenzia.value,
			entities: data,
		};
		console.log(toSend);

		axiosInstance
			.put(`/dashboard/docs/${id}`, toSend)
			.then(({ data }) => {
				history.push("/dashboard");
				enqueueSnackbar(`Documento ${id} validato correttamente`, {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar("Errore", {
					variant: "error",
				});
			});
	};

	function onDelete() {
		console.log("onDelete");
		axiosInstance
			.delete(`/dashboard/docs/${id}`)
			.then(({ data }) => {
				history.push("/dashboard");
				enqueueSnackbar(`Documento ${id} eliminato correttamente`, {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar("Errore", {
					variant: "error",
				});
			});
	}

	function onSave() {
		console.log("onSave");
	}

	return (
		<form id="form" onSubmit={handleSubmit(onSubmit)}>
			<Box id="documentActionsToolbar" className={classes.actions}>
				{actions.map((action, index) => (
					<Tooltip
						key={index}
						title={action.label}
						aria-label={action.label}
					>
						<Fab
							elevation={0}
							onClick={(event) => action.handle(index)}
							className={clsx(
								classes.action,
								{
									[classes.dangerAction]:
										action.color === "danger",
								},
								{
									[classes.successAction]:
										action.color === "success",
								}
							)}
							type={action.type}
						>
							{action.icon}
						</Fab>
					</Tooltip>
				))}
			</Box>

			<Grid container spacing={2}>
				{entities.map((entity, index) => {
					return (
						<Grid item xs={12} key={index} sm={6}>
							<Field
								entity={entity}
								error={errors[entity.name]}
								entityRef={register({
									pattern: matchPattern(entity.pattern),
									required: entity.mandatory,
								})}
							/>
						</Grid>
					);
				})}
				{/* <Button
					color="secondary"
					variant="contained"
					fullWidth
					type="submit"
					size="large"
					className={classes.submitButton}
				>
					Invia
				</Button> */}
			</Grid>
		</form>
	);
}
