import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Document } from "components/Document";
import { Overview } from "components/Overview";

export function Dashboard() {
	const match = useRouteMatch();

	return (
		<Switch>
			<Route path={`${match.path}/:id`}>
				<Document />
			</Route>
			<Route path={match.path}>
				<Overview />
			</Route>
		</Switch>
	);
}
