import React, { useState, createContext, useContext, useRef } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";

const ConfirmerContext = createContext({});

export function ConfirmerProvider({ children }) {
	const [values, setValues] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const resolver = useRef();

	function handleShow({
		title = "Confirm",
		body = "",
		cancelLabel = "Cancel",
		confirmLabel = "Confirm",
	}) {
		setIsOpen(true);
		setValues({ title, body, cancelLabel, confirmLabel });

		return new Promise(function (resolve) {
			resolver.current = resolve;
		});
	}

	function handleConfirm() {
		if (resolver.current) {
			resolver.current(true);
		}

		setIsOpen(false);
	}

	function handleCancel() {
		resolver.current && resolver.current(false);

		setIsOpen(false);
	}

	return (
		<ConfirmerContext.Provider value={{ show: handleShow }}>
			<>
				{children}
				<Dialog open={isOpen} onClose={handleCancel}>
					<DialogTitle>{values.title}</DialogTitle>
					<DialogContent>
						<DialogContentText>{values.body}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCancel} color="primary">
							{values.cancelLabel}
						</Button>
						<Button
							onClick={handleConfirm}
							color="primary"
							autoFocus
						>
							{values.confirmLabel}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		</ConfirmerContext.Provider>
	);
}

export const useConfirm = () => useContext(ConfirmerContext).show;

ConfirmerProvider.propTypes = {
	children: PropTypes.element,
};
