/* eslint-disable react/display-name */
import React, { createRef, useEffect, useState } from "react";
import { Box, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialTable, { MTableCell, MTableToolbar } from "material-table";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { tableIntl } from "config/constants";
import { axiosInstance } from "services/api";
import { useConfirm } from "shared/ConfirmerProvider";
import { DatePicker } from "shared/DatePicker";
import { Filter } from "shared/Filter";
import { Loader } from "shared/Loader";
import "moment/locale/it";

moment.locale("it");

const useStyles = makeStyles((theme) => ({
	table: {
		width: "100%",
		marginTop: theme.spacing(4),
	},
	removeAllFilters: {
		color: theme.palette.primary.main,
		marginLeft: "0.5rem",
		cursor: "pointer",
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const headerStyle = {
	padding: "2px 16px",
	position: "sticky",
	fontSize: "0.8125rem",
	top: 0,
};

const filterCellStyle = {
	backgroundColor: "white",
	padding: "4px 16px",
	position: "sticky",
	top: 25,
};

const cellStyle = {
	fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
	width: "150px",
	minWidth: "150px",
	maxWidth: "150px",
	overflow: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
	padding: "2px 16px",
};

const actionsCellStyle = {
	textAlign: "right",
};

export function Table({ statuses, onTotalChange }) {
	const classes = useStyles();
	const query = useQuery();
	const confirm = useConfirm();
	const history = useHistory();
	const tableRef = createRef();
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = {
		Agenzia: query.get("agenzia") && query.get("agenzia").split(","),
		"Tipo richiesta":
			query.get("sub_label") && query.get("sub_label").split(","),
		"Numero polizza": query.get("numero_polizza"),
		// Contraente: query.get("contraente"), DA INSERIRE IN UN SECONDO MOMENTO
		Mittente: query.get("email_sender"),
		Origine: query.get("sender"),
		Stato: query.get("stato"),
		Data: query.get("email_date"),
		// skip: query.get("skip"),
		// limit: query.get("limit"),
		// order: query.get("order"),
		// order_by: query.get("order_by"),
	};

	const [filters, setFilters] = useState(queryParams);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);

	// WORKAROUND PER NON PERDERE I VALUES DEI FILTRI AL REFRESH (MATERIAL TABLE BUG)
	const [columns] = useState([
		{
			title: "Data",
			field: "email_date",
			cellStyle,
			defaultFilter: filters.Data,
			type: "date",
			filterComponent: (props) => (
				<DatePicker
					value={filters.Data}
					onChange={(e) =>
						// eslint-disable-next-line react/prop-types
						props.onFilterChanged(props.columnDef.tableData.id, e)
					}
				/>
			),
		},
		{
			title: "Tipo richiesta",
			field: "sub_label",
			cellStyle,
			defaultFilter: filters["Tipo richiesta"],
			lookup: { REVOCA: "Revoca", ADESIONE: "Adesione" },
		},
		{
			title: "Compagnia",
			field: "agenzia",
			cellStyle,
			defaultFilter: filters.Agenzia,
			lookup: { RMA: "RMA", ITA: "ITA" },
		},
		{
			title: "Numero Polizza",
			field: "numero_polizza",
			defaultFilter: filters["Numero polizza"],
			cellStyle,
		},
		{
			title: "Contraente",
			field: "contraente",
			defaultFilter: filters.Contraente,
			cellStyle,
		},
		{
			title: "Mittente",
			field: "email_sender",
			defaultFilter: filters.Mittente,
			cellStyle,
		},
		{
			title: "Origine",
			field: "sender",
			defaultFilter: filters.Origine,
			cellStyle,
		},
		{
			title: "Stato",
			field: "stato",
			defaultFilter: filters.Stato,
			cellStyle,
		},
	]);

	function handleDeleteFilter(key) {
		const newFilters = { ...filters };
		delete newFilters[key];
		query.delete(key);

		// history.push({
		// 	pathname: "/dashboard",
		// 	search: query.toString(),
		// });

		setFilters(newFilters);
		tableRef.current.onQueryChange();
	}

	function handleTableRefresh() {
		tableRef.current.onQueryChange();
	}

	function onDelete(id) {
		axiosInstance
			.delete(`/dashboard/docs/${id}`)
			.then(() => {
				enqueueSnackbar(`Documento ${id} eliminato correttamente`, {
					variant: "success",
				});
				tableRef.current.onQueryChange();
			})
			.catch(() => {
				enqueueSnackbar(
					"Non è stato possibile eliminare il documento",
					{
						variant: "error",
					}
				);
			});
	}

	function onSave(id) {
		console.log("onSave");
	}

	function onValidate(id) {
		axiosInstance
			.put(`/dashboard/docs/${id}`)
			.then(({ data }) => {
				enqueueSnackbar(`Documento ${id} approvato correttamente`, {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(
					`Non è stato possibile approvare il documento`,
					{
						variant: "error",
					}
				);
			});
	}

	useEffect(() => {
		if (tableRef.current && statuses) {
			tableRef.current.onQueryChange();
		}
	}, [statuses]);

	function refreshData(query) {
		return new Promise((resolve, reject) => {
			if (!statuses) return;

			function generateQueryParams(query) {
				const toSend = {
					status: statuses.toString(),
					skip: query.pageSize * query.page,
					limit: query.pageSize,
					order: query.orderDirection === "asc" ? 1 : -1,
					order_by: query.orderBy?.field || "email_date",
				};

				const newFilters = {};

				query.filters.forEach((filter) => {
					if (filter.value.toString()) {
						console.log(filter);
						newFilters[filter.column.title] = `${
							filter.column.title
						}: ${filter.value.toString()}`;

						toSend[filter.column.field] = filter.value.toString();
					}
				});

				setFilters(newFilters);

				history.push({
					pathname: "/dashboard",
					search: new URLSearchParams(toSend).toString(),
				});

				return toSend;
			}

			setLoading(true);
			axiosInstance
				.get("/dashboard/docs", {
					params: generateQueryParams(query),
				})
				.then(({ data }) => {
					setTotal(data.total);
					onTotalChange(data.total);
					resolve({
						data: data.documents,
						page: query.page,
						totalCount: data.total,
					});
					setLoading(false);
				})
				.catch((err) => {
					reject(err);
					setLoading(false);
				});
		});
	}

	return (
		<Box className={classes.table}>
			<Loader isLoading={loading || !statuses}>
				<MaterialTable
					title={`Lista richieste (${total})`}
					tableRef={tableRef}
					columns={columns}
					localization={tableIntl}
					actions={[
						{
							icon: "delete",
							iconProps: {
								padding: "0",
								fontSize: "small",
							},
							tooltip: "Chiude richesta",
							onClick: (event, rowData) => onDelete(rowData._id),
						},
						{
							icon: "save",
							iconProps: {
								padding: "0",
								fontSize: "small",
							},
							tooltip: "Salva richiesta senza passare per l'RPA",
							onClick: (event, rowData) => onSave(rowData._id),
						},
						{
							icon: "check",
							iconProps: {
								padding: "0",
								fontSize: "small",
							},
							tooltip: "Approva richiesta",
							onClick: (event, rowData) => {
								console.log(rowData);
								// onValidate(rowData._id)
							},
						},
						{
							icon: () => <RefreshIcon />,
							tooltip: "Aggiorna lista",
							isFreeAction: true,
							onClick: (event) => handleTableRefresh(),
						},
					]}
					onRowClick={(evt, selectedRow) =>
						history.push(`/dashboard/${selectedRow._id}`)
					}
					options={{
						draggable: false,
						search: false,
						filtering: true,
						actionsColumnIndex: -1,
						pageSize: 20,
						pageSizeOptions: [10, 20, 50, 100],
						loadingType: null,
						headerStyle,
						filterCellStyle,
						actionsCellStyle,
						maxBodyHeight: 400,
					}}
					components={{
						Cell: (props) => (
							<Tooltip
								title={
									props.value
										? props.columnDef.type === "date" ||
										  props.columnDef.type === "datetime"
											? moment(props.value).format(
													"DD/MM/YYYY, h:mm:ss"
											  )
											: props.value
										: ""
								}
								placement="top-start"
								arrow
							>
								<MTableCell {...props} />
							</Tooltip>
						),
						Toolbar: (props) => (
							<div>
								<MTableToolbar {...props} />
								{!loading && (
									<div
										style={{
											margin: "-1rem 1rem 2rem",
											padding: "0px 10px",
										}}
									>
										<Grid
											container
											spacing={(4, 2)}
											style={{
												marginTop: "1rem",
												display: "flex",
												alignItems: "center",
											}}
										>
											{Object.keys(filters)
												.filter((key) => filters[key])
												.map((key) => (
													<Filter
														key={key}
														label={filters[key]}
														onDelete={() =>
															handleDeleteFilter(
																key
															)
														}
													/>
												))}
											{Object.keys(filters).length >
												0 && (
												<Typography
													variant="body2"
													onClick={(event) => {
														console.log(
															"Rimuovi filtri"
														);
													}}
													className={
														classes.removeAllFilters
													}
												>
													Rimuovi tutti i filtri
												</Typography>
											)}
										</Grid>
									</div>
								)}
							</div>
						),
					}}
					data={refreshData}
				/>
			</Loader>
		</Box>
	);
}

Table.propTypes = {
	statuses: PropTypes.arrayOf(PropTypes.string),
};
