import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Select,
  Typography,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import logo from "assets/images/logo.png";
import { EntitiesForm } from "components/Forms";
import { axiosInstance } from "services/api";
import { Field } from "shared/Field";
import { Footer } from "shared/Footer";
import { Loader } from "shared/Loader";
import { matchPattern } from "utils";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  content: {
    backgroundImage: "url('assets/images/landing-page-background.png')",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto 400px",
    },
  },
  paper: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    transition: "all 0.3s",
    justifyContent: "center",
    padding: theme.spacing(2),
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: "black",
      background: "transparent",
    },
  },
  title: {
    color: "white",
    textShadow: "0 0 7px #000",
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: "center",
  },
  successMessage: {
    textAlign: "center",
  },
  countdown: {
    color: "#317ED9",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  errorAlertIcon: {
    color: "#D16405",
    fontSize: 16,
  },
  privacyCheckboxLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  submitButton: {
    borderRadius: 0,
    minWidth: 240,
  },
  footerLinks: {
    fontFamily: "'Titillium Web', sans-serif",
    px: 15,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      px: 2,
    },
  },
}));

export function Validate() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, reset, handleSubmit } = useForm();

  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const cert = new URLSearchParams(useLocation().search).get("cert");

  useEffect(() => {
    axiosInstance
      .get(`/services/retrieve_fields_from_certificate?certificato=${cert}`)
      .then(({ data }) => {
        data.overview = data;
        setDocument(data);

        const values = data.entities.reduce(function (acc, curr) {
          acc[curr.name] = curr.value;
          return acc;
        }, {});

        // SETTO I VALORI DI DEFAULT AL FORM
        reset(values);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data.description ||
          "Impossibile ricavare le informazioni";
        setError(errorMessage);
        setLoading(false);
      });
  }, [cert]);

  useEffect(() => {
    console.log(document);
  }, [document]);

  function onSubmit(data) {
    const toSend = {
      label: document.label,
      sub_label: document.sub_label,
      sender: document.sender,
      agenzia: document.agenzia,
      entities: data,
    };

    axiosInstance
      .post(`/services/validate_from_certificato?certificato=${cert}`, toSend)
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data.description ||
          "Impossibile inviare la richiesta";

        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  }

  return (
    <Box id="landing_page">
      <Box id="header" className={classes.header}>
        <Container id="container" fixed={true} maxWidth="md">
          <Grid item xs={12} className={classes.logo}>
            <img src={logo} alt="Reale Mutua logo" width="170" height="42" />
          </Grid>
        </Container>
      </Box>
      <Box id="content" className={classes.content}>
        <Container id="form_container" fixed>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} sm={10} md={7}>
              <Box my={4}>
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.title}
                >
                  Richiesta dati per disdetta polizza
                </Typography>
              </Box>
              <Box my={4} pb={8} position="relative">
                <Loader isLoading={loading} spinnerSize={75}>
                  <Paper
                    square
                    elevation={5}
                    style={{
                      minHeight: "50vh",
                      padding: "2rem 2rem 4rem",
                    }}
                  >
                    {error ? (
                      <Typography
                        variant="h5"
                        paragraph={true}
                        className={classes.errorMessage}
                      >
                        {error}
                      </Typography>
                    ) : (
                      <>
                        {submitted ? (
                          <Typography
                            variant="h5"
                            paragraph={true}
                            className={classes.successMessage}
                          >
                            Abbiamo ricevuto la tua richiesta. Grazie
                          </Typography>
                        ) : (
                          !loading && (
                            <>
                              <Box mb={4}>
                                <Typography
                                  variant="subtitle1"
                                  paragraph={true}
                                  className={classes.countdown}
                                >
                                  Inserisci i tuoi dati
                                </Typography>
                              </Box>

                              <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                  {document.entities.map((entity) => (
                                    <Grid key={entity.name} item xs={12} sm={6}>
                                      <Field
                                        entity={entity}
                                        error={errors[entity.name]}
                                        entityRef={register({
                                          pattern: matchPattern(entity.pattern),
                                          required: entity.mandatory,
                                        })}
                                      />
                                    </Grid>
                                  ))}

                                  <FormControl
                                    required
                                    error={!!errors["checkbox"]}
                                  >
                                    <FormControlLabel
                                      className={classes.privacyCheckboxLabel}
                                      name="checkbox"
                                      inputRef={register({
                                        required: true,
                                      })}
                                      control={<Checkbox color="primary" />}
                                      label={
                                        <span>
                                          Dichiaro di aver letto{" "}
                                          <Link
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setPrivacyModalOpen(true);
                                            }}
                                          >
                                            l&apos;informativa sulla privacy
                                          </Link>
                                        </span>
                                      }
                                      //"Dichiaro di aver letto l'informativa sulla privacy"
                                    />
                                    {!!errors["checkbox"] && (
                                      <FormHelperText>Richiesto</FormHelperText>
                                    )}
                                  </FormControl>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    size="large"
                                    className={classes.submitButton}
                                  >
                                    Invia
                                  </Button>
                                </Grid>
                              </form>
                            </>
                          )
                        )}
                      </>
                    )}
                  </Paper>
                </Loader>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      >
        <DialogTitle>Informativa sulla privacy</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I dati personali richiesti saranno trattati per le finalità e
            secondo le modalità indicate nell’informativa privacy per contraenti
            e assicurati redatta ai sensi degli artt. 13 e 14 del Regolamento
            (UE) 2016/679 (Regolamento generale sulla protezione dei dati) che
            le è stata consegnata in virtù del rapporto contrattuale in essere
            con la società; potrà consultare l’informativa nella sezione “cookie
            e privacy” sul sito della Compagnia.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPrivacyModalOpen(false)}
            color="primary"
            autoFocus
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </Box>
  );
}
