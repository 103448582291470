import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Typography,
	Link,
	Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TodayIcon from "@material-ui/icons/Today";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { UploadForm, EntitiesForm } from "components/Forms";
import { axiosInstance } from "services/api";
import { Email } from "shared/Email";

const danger = "#D16405";

const useStyles = makeStyles((theme) => ({
	paper: {
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		transition: "all 0.3s",
		justifyContent: "center",
		padding: theme.spacing(2),
		cursor: "pointer",
		color: theme.palette.text.secondary,
		"&:hover": {
			color: "black",
			background: "transparent",
		},
	},
	header: {
		paddingBottom: theme.spacing(3),
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		display: "flex",
		alignItems: "flex-end",
	},
	errorMessage: {
		color: danger,
		textAlign: "center",
	},
	successMessage: {
		textAlign: "center",
	},
	formControl: {
		marginBottom: theme.spacing(2),
		width: "100%",
	},
	errorAlertIcon: {
		color: danger,
		fontSize: 16,
	},
	uploadButtonWrapper: {
		margin: "1rem 0",
		display: "flex",
		alignItems: "center",
	},
	uploadButton: {
		margin: "1rem 0",
		borderRadius: 0,
		minWidth: 240,
	},
	fileUploaded: {
		marginRight: "1rem",
		color: "#888888",
	},
	toolbar: {
		textAlign: "right",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
	},
	date: {
		color: "#888888",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		marginBottom: "1rem",
		flexDirection: "row-reverse",
	},
}));

export function Document() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { id } = useParams();

	// const { register, handleSubmit, errors, reset } = useForm();

	const [document, setDocument] = useState(null);
	const [entities, setEntities] = useState(null);
	const [loading, setLoading] = useState(true);
	// const [submitted, setSubmitted] = useState(false);
	// const [error, setError] = useState(null);
	// const [errorRPA, setErrorRPA] = useState(true);
	// const [isaac, setIsaac] = useState(true);
	const [textractor, setTextractor] = useState(true);
	const [file, setFile] = useState(null);

	const fileInput = useRef(null);

	const history = useHistory();

	// useEffect(() => {
	// 	if (fileInput && fileInput.files?.length)
	// 	{console.log(fileInput.files[0]);
	// 		setFile(fileInput.files[0].name);}

	// }, [fileInput]);

	const handleSetFile = (fileToUpload) => {
		console.log(fileToUpload);
		setFile(fileToUpload);
	};

	const handleSetTextractor = (textractor) => {
		setTextractor(textractor);
	};

	useEffect(() => {
		if (id) {
			axiosInstance
				.get(`/dashboard/docs/${id}`)
				.then(({ data }) => {
					setDocument(data);
					setLoading(false);
				})
				.catch(() => {
					enqueueSnackbar("Errore", {
						variant: "error",
					});
				});
		}
	}, [id]);

	useEffect(() => {
		if (
			document &&
			document.overview.label.value &&
			document.overview.sub_label.value
		) {
			console.log(document);
			setLoading(true);
			axiosInstance
				.post(`/dashboard/docs/${id}`, {
					agenzia: document.overview.agenzia.value,
					label: document.overview.label.value,
					sub_label: document.overview.sub_label.value,
				})
				.then(({ data }) => {
					setEntities(data);

					// const values = data.reduce(function (acc, curr) {
					// 	acc[curr.name] = curr.value;
					// 	return acc;
					// }, {});

					// console.log(values);

					// SETTO I VALORI DI DEFAULT AL FORM
					// reset(values);
					setLoading(false);
				})
				.catch(() => {
					enqueueSnackbar("Errore", {
						variant: "error",
					});
				});
		} else {
			setEntities([]);
		}
	}, [document]);

	function onUpload() {
		const form = new FormData();
		form.append("file", file);

		axiosInstance
			.post(
				`/dashboard/docs/${id}/file?agenzia=${document.overview.agenzia.value}`,
				form,
				{
					headers: { "Content-Type": `multipart/form-data` },
				}
			)
			.then(({ data }) => {
				console.log(data);
				setEntities(data);
			})
			.catch((error) => {
				enqueueSnackbar("Errore", {
					variant: "error",
				});
			});
	}

	// const handleSubmit = data => {
	// 	const toSend = {
	// 		label: document.overview.label.value,
	// 		sub_label: document.overview.sub_label.value,
	// 		sender: document.overview.sender.value,
	// 		agenzia: document.overview.agenzia.value,
	// 		entities: data,
	// 	};

	// 	console.log(toSend);
	// 	axiosInstance
	// 		.put(`/dashboard/docs/${id}`, toSend)
	// 		.then(({ data }) => {
	// 			history.push("/dashboard");
	// 			enqueueSnackbar(`Documento ${id} approvato correttamente`, {
	// 				variant: "success",
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			enqueueSnackbar("Errore", {
	// 				variant: "error",
	// 			});
	// 		});
	// }

	return (
		<Grid container justify="center" spacing={2}>
			<Grid item xs={12}>
				<Paper
					style={{
						minHeight: "50vh",
						padding: "2rem 2rem 4rem",
					}}
				>
					{/* {error ? (
						<Typography
							variant="h5"
							paragraph={true}
							className={classes.errorMessage}
						>
							ERRORE: {error.message}
						</Typography>
					) : (
						<> */}
					{loading ? (
						<Grid container direction="row" justify="center">
							<Box p={10}>
								<CircularProgress size={75} />
							</Box>
						</Grid>
					) : (
						<>
							<Grid
								container
								spacing={2}
								className={classes.header}
							>
								<Grid item xs={12} sm={6}>
									<Box>
										<Typography variant="h6">
											Stato richiesta:{" "}
											{document.overview.stato.value}
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6">
											Origine:{" "}
											{
												document.overview.provenienza
													.value
											}
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6">
											Compagnia:{" "}
											{document.overview.agenzia.value}
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6">
											Classificazione:{" "}
											{document.overview.label.value}
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6">
											Tipologia:{" "}
											<Select
												placeholder={
													document.overview.sub_label
														.value
												}
												onChange={(event) =>
													console.log(
														event.target.value
													)
												}
											>
												<option
													value={
														document.overview
															.sub_label.value
													}
												>
													{
														document.overview
															.sub_label.value
													}
												</option>
												<option value="option2">
													Option 2
												</option>
											</Select>
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6">
											Data: {document.email.date.value}
										</Typography>
									</Box>
									{document.overview.stato.value ===
										"isaac" &&
										document.overview.isaac_link.value && (
											<Box>
												<Typography variant="h6">
													<Link
														href={
															document.overview
																.isaac_link
																.value
														}
														color="primary"
														target="_blank"
													>
														{
															document.overview
																.isaac_link
																.label
														}
													</Link>
												</Typography>
											</Box>
										)}
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									className={classes.toolbar}
								>
									<Box className={classes.date}>
										<TodayIcon />
										<Typography
											variant="subtitle2"
											style={{
												margin: "0 6px",
											}}
										>
											{document.email.date.value}
										</Typography>
									</Box>
								</Grid>
							</Grid>

							{/* {document.stato && (
								<Grid item xs={12}>
									<Typography
										variant="subtitle2"
										className={clsx(
											classes.errorMessage,
											classes.header
										)}
									>
										Errore: {document.stato}
									</Typography>
								</Grid>
							)} */}

							{entities && (
								<EntitiesForm
									overview={document.overview}
									entities={entities}
									id={id}
								/>
							)}

							{document.overview.stato.value === "isaac" && (
								<UploadForm
									onUpload={onUpload}
									handleSetTextractor={handleSetTextractor}
									handleSetFile={handleSetFile}
									file={file}
									textractor={textractor}
								/>
							)}
						</>
					)}

					{document && document.overview.provenienza !== "P" && (
						<Email email={document.email} id={id} />
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}
