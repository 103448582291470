import React, { useState } from "react";
// eslint-disable-next-line import/order
import {
	DatePicker as MDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/it";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Today } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";

moment.locale("it");

export function DatePicker({ value = null, onChange }) {
	const [date, setDate] = useState(value);

	return (
		<MuiPickersUtilsProvider
			libInstance={moment}
			utils={MomentUtils}
			locale="it"
		>
			<MDatePicker
				format="DD/MM/YYYY"
				clearLabel="Pulisci"
				cancelLabel="Annulla"
				okLabel="Conferma"
				value={date || null}
				onChange={(e) => {
					setDate(e);
					onChange(e ? moment(e).format("YYYY-MM-DD") : null);
				}}
				clearable
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<IconButton style={{ padding: 0 }}>
								<Today />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

DatePicker.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
};
