import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import rootReducer from "./reducers";

export const history = createBrowserHistory();

const store = configureStore({
	reducer: rootReducer,
	middleware: [...getDefaultMiddleware(), routerMiddleware(history)],
});

export default store;
