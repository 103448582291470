import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Loader } from "./Loader";

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		minHeight: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: theme.spacing(1, 2),
		cursor: "pointer",
		border: "1px solid",
		borderColor: "transparent",
		overflow: "hidden",
	},
	selected: {
		borderColor: theme.palette.primary.main,
	},
	statLabel: {
		maxWidth: "75%",
		fontSize: "87.5%",
		paddingRight: "4px",
	},
	statValue: {
		fontWeight: "bold",
		color: theme.palette.secondary.main,
	},
}));

export function Stat({
	label,
	value,
	isLoading,
	selected,
	total,
	handleClick,
}) {
	const classes = useStyles();

	return (
		<Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
			<Loader isLoading={isLoading}>
				<Paper
					className={clsx(classes.paper, {
						[classes.selected]: selected,
					})}
					onClick={handleClick}
					title={`${label}: ${value}`}
				>
					<Typography
						variant="subtitle2"
						noWrap
						className={classes.statLabel}
						color="primary"
					>
						{label}
					</Typography>
					<Typography
						variant="subtitle2"
						className={classes.statValue}
					>
						{value}
					</Typography>
				</Paper>
			</Loader>
		</Grid>
	);
}

Stat.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	selected: PropTypes.bool,
	handleClick: PropTypes.func,
};
