import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: 0,
};

export const examplesStore = createSlice({
	name: "examples",
	initialState,
	reducers: {
		increment(state, action) {
			console.log("Action :", action);
			state.value = state.value + 1;
		},
	},
});

export const { increment } = examplesStore.actions;
