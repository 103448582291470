import React, { useEffect, useState } from "react";
import { axiosInstance } from "services/api";
import { Stats } from "./Stats";
import { Table } from "./Table";

export function Overview() {
	const [total, setTotal] = useState(null);
	const [stats, setStats] = useState(null);
	const [selected, setSelected] = useState(null);
	const [statuses, setStatuses] = useState(null);

	useEffect(() => {
		axiosInstance.get("dashboard/stats").then(({ data }) => {
			setSelected(data.map((stat) => stat.status_name));
			setStatuses(data.map((stat) => stat.status_name));
			setStats(data);
		});
	}, []);

	function handleStatClick(selectedStat) {
		if (selectedStat === "allStatuses") {
			setSelected(statuses);
		} else {
			setSelected([selectedStat]);
		}
		// if (selected.includes(selectedStat)) {
		// 	setSelected(selected.filter((stat) => stat !== selectedStat));
		// } else {
		// }
	}

	function handleTotalChange(total) {
		setTotal(total);
	}

	return (
		<>
			<Stats
				stats={stats}
				selected={selected}
				total={total}
				onStatClick={handleStatClick}
			/>
			<Table statuses={selected} onTotalChange={handleTotalChange} />
		</>
	);
}
