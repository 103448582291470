import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { statsLength } from "config/constants";
import { Stat } from "shared/Stat";

const useStyles = makeStyles((theme) => ({
	statsContainer: {
		// flexWrap: "nowrap",
		// overflow: "auto",
	},
}));

export function Stats({ stats, total, selected = [], onStatClick }) {
	const classes = useStyles();

	return (
		<Grid container spacing={(4, 2)} className={classes.statsContainer}>
			<Stat
				label="Totale"
				value={total}
				selected={selected === "all"}
				handleClick={() => onStatClick("allStatuses")}
				isLoading={total === null}
			/>
			{stats
				? stats.map((stat) => (
						<Stat
							key={stat.status_name}
							label={stat.status_label}
							value={stat.status_count}
							handleClick={() => onStatClick(stat.status_name)}
							selected={selected.includes(stat.status_name)}
						/>
				  ))
				: statsLength.map((n) => <Stat key={n} isLoading />)}
		</Grid>
	);
}

Stats.propTypes = {
	stats: PropTypes.arrayOf(
		PropTypes.shape({
			status_name: PropTypes.string.isRequired,
			status_label: PropTypes.string.isRequired,
			status_count: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
		})
	),
	total: PropTypes.number,
	selected: PropTypes.arrayOf(PropTypes.string),
	onStatClick: PropTypes.func,
};
